
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {
  ISbStoryData,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import StoryblokSeo from '@/app/common/components/StoryblokSeo/StoryblokSeo'
import {
  commonRelations,
  getWithRelationsAndLinks,
} from '@/app/common/storyblok'
import { prefetchCustomDealForm } from '@/blocks/CustomDealForm/CustomDealForm'
import { prefetchNewProductSelectorMainProducts } from '@/blocks/NewProductSelector/ProductSelectorMainProducts'
import { prefetchNewProductSelectorOtherProducts } from '@/blocks/NewProductSelector/ProductSelectorOtherProducts'
import {
  getProductsLegacyWithPaginationQueryVariables,
  prefetchProductSelector,
} from '@/blocks/ProductSelector/ProductSelector'
import config from '@/config'
import { getArticles } from '@/domains/blogs/createGetServerPropsForBlogPage'
import {
  createGetServerSideProps,
  getSBRegionFolder,
} from '@/helpers/createGetServerSideProps'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { isPreview } from '@/helpers/setCacheControl'
import { getLanguageCodeFromLocale } from '@/helpers/useRegionCode'
import {
  BlogArticleStoryblok,
  PageStoryblok,
  RedirectStoryblok,
} from '@/types/storyblok-component-types'
import findStoryblokComponent from '@/utils/findStoryblokComponent'

interface StoryblokPageProps {
  story: ISbStoryData<PageStoryblok>
  articles?: ISbStoryData<BlogArticleStoryblok>[]
  initialQueryVariables: {}
}

function StoryblokPage(props: StoryblokPageProps) {
  const story = useStoryblokState(props.story)

  if (!story) {
    throw new Error('Empty story!')
  }

  const blogComponentProps = props.articles
    ? { latestArticle: props.articles?.[0] }
    : {}

  return (
    <>
      <StoryblokSeo
        story={story}
        metatags={story.content.metatags}
        canonicalPath={story.content.canonical_path}
      />
      <StoryblokComponent blok={story.content} {...blogComponentProps} />
    </>
  )
}

const contentTypeComponents = [
  'page',
  'blog-article',
  'blog-landing-page',
  'about-page',
  'redirect',
]

 const _getServerSideProps = createGetServerSideProps({
  async getServerSideProps({ client, context, storyblokApi, regionCode }) {
    const slug = context.params?.slug ?? []
    if (
      !isPreview(context) &&
      config.hidePlaygroundStories &&
      slug[0] === 'playground'
    ) {
      console.log('Hiding playground story', slug.join('/'))
      return { notFound: true }
    }

    // for development, we can't define index page at root folder of story block
    // so we need to use /home instead
    if (config.isStoryblokDevSpace && slug.length === 0) {
      slug.push('home')
    }

    try {
      const storyblokSlug = config.isStoryblokDevSpace
        ? `cdn/stories/${slug.join('/')}`
        : `cdn/stories/${[getSBRegionFolder(regionCode), ...slug].join('/')}`

      const { data } = await getWithRelationsAndLinks({
        slug: storyblokSlug,
        storyblokApi,
        context,
        params: {
          resolve_relations: commonRelations,
        },
      })

      if (
        !data ||
        (!contentTypeComponents.includes(data.story.content.component) &&
          !isPreview(context))
      ) {
        console.log(data ? 'Hidden content type' : 'Failed to fetch story', {
          storyblokSlug,
        })
        return { notFound: true }
      }

      const story = data.story as
        | ISbStoryData<PageStoryblok>
        | ISbStoryData<RedirectStoryblok>
      if (isRedirectStory(story)) {
        const link = getStoryBlokLink(story.content.target) || '/'
        const localizedLink =
          context.locale === context.defaultLocale
            ? link
            : `/${context.locale}${link}`

        return {
          redirect: {
            permanent: !story.content.temporary,
            destination: localizedLink,
          },
        }
      }

      const customDealFormStory = findStoryblokComponent(
        story,
        'custom-deal-form',
      )

      if (customDealFormStory && customDealFormStory.category_id) {
        const variables = {
          categoryId: customDealFormStory.category_id,
          languageCode: getLanguageCodeFromLocale(context.locale),
        }
        await prefetchCustomDealForm(client, variables)
      }

      const productSelectorStory = findStoryblokComponent(
        story,
        'product-selector',
      )

      if (productSelectorStory && productSelectorStory.product_id) {
        await prefetchProductSelector(
          client,
          getProductsLegacyWithPaginationQueryVariables({
            paths: productSelectorStory.product_id.split(','),
            maximumProductsPerPage: Number(
              productSelectorStory.maximum_products_per_page,
            ),
            pageIndex:
              typeof context.query.page === 'string'
                ? parseInt(context.query.page)
                : 1,
            hasOtherForm: !!getStoryBlokLink(
              productSelectorStory.other_form_link,
            ),
          }),
        )
      }

      const newProductSelectorComponent = findStoryblokComponent(
        story,
        'new-product-selector',
      )
      if (newProductSelectorComponent) {
        const page =
          typeof context.query.page === 'string'
            ? Number.parseInt(context.query.page)
            : 1

        if (isNaN(page)) {
          return { notFound: true }
        }

        let cardsCount: number
        const otherProducts = context.query.other_products === '1'
        if (!otherProducts) {
          const res = await prefetchNewProductSelectorMainProducts(client, {
            productIds: newProductSelectorComponent.products,
            categoryIds: newProductSelectorComponent.categories ?? [],
          })

          cardsCount = res.data.products.length
          if (res.data.otherProducts.length > 0) {
            cardsCount += 1
          } else if (newProductSelectorComponent.missing_product_link) {
            cardsCount += 1
          }
        } else {
          if (
            !newProductSelectorComponent.categories ||
            !newProductSelectorComponent.categories.length
          ) {
            return { notFound: true }
          }

          const res = await prefetchNewProductSelectorOtherProducts(client, {
            categoryIds: newProductSelectorComponent.categories,
            excludeIds: newProductSelectorComponent.products,
          })

          cardsCount = newProductSelectorComponent.missing_product_link
            ? res.data.products.length + 1
            : res.data.products.length
        }

        const pages = Math.ceil(
          cardsCount / parseInt(newProductSelectorComponent.page_size),
        )

        if (page > pages) {
          return { notFound: true }
        }
      }

      if (
        config.env !== 'production' && // production uses dedicated pages/blog/* pages
        (slug?.includes('blog') || slug?.includes('all-articles'))
      ) {
        return {
          props: {
            story,
            articles: await getArticles(
              storyblokApi,
              context,
              slug?.includes('blog') ? 'homePage' : 'allArticlesPage',
              regionCode,
            ),
            initialQueryVariables: {},
          },
        }
      }

      return {
        props: {
          story,
          initialQueryVariables: {},
        },
      }
    } catch (error) {
      console.error(error)
      if (typeof error !== 'string') {
        throw error
      }

      try {
        const parsedError = JSON.parse(error)
        if (
          parsedError &&
          typeof parsedError === 'object' &&
          (parsedError as Record<string, any>).status === 404
        ) {
          return { notFound: true }
        }
      } catch {
        throw error
      }

      throw error
    }
  },
})

export default StoryblokPage

function isRedirectStory(
  story: ISbStoryData<PageStoryblok | RedirectStoryblok>,
): story is ISbStoryData<RedirectStoryblok> {
  return story.content.component === 'redirect'
}


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[[...slug]]',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  